import React from 'react';
import logo from '../../assets/logo.webp';

// @ts-ignore
import { ReactComponent as Logo } from '../../assets/logo.svg';

import './Header.scss';
import {useBreakpoint} from "../../utils/useBreakpoint";

type HeaderProps = {
    header: {
        title: string;
        subtitle: string;
        button: { name: string; url: string; };
        cards: { label: string; title: string; info: string; }[];
    };
};

const Header = ({ header }: HeaderProps) => {
    const useDevise = useBreakpoint();

    return (
        <div className="header">
            <div className="header__logo">
                <img src={logo} alt="Сбер"/>
            </div>
            <div className="header__bgTopImage">
                <img
                    src=" https://hbekcloeqy.a.trbcdn.net/common/assets/sberlabs/1.png"
                    alt="дополнительный элемент фона" />
            </div>
            <div className="header__logo" />
            <div className="header__title">{header.title}</div>
            <div className="header__subtitle">{header.subtitle}</div>
            {/* {useDevise.breakpoint !== 'xs' && (
                <a className="header__btn" target="_blank" href={header.button.url}>{header.button.name}</a>
            )} */}
            {/* <div className="header__cards">
                {header.cards.map((card, i) => {
                    return (
                        <div key={i} className="header__cards_card">
                            <div className="header__cards_card-label">{card.label}</div>
                            <div className="header__cards_card-infoContainer">
                                <div className="header__cards_card-title">{card.title}</div>
                                <div className="header__cards_card-info">{card.info}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {useDevise.breakpoint === 'xs' && (
                <a className="header__btn" target="_blank" href={header.button.url}>{header.button.name}</a>
            )} */}
        </div>
    );
}

export default Header;
