import React, {useEffect, useRef, useState} from 'react';

import Header from './Sections/header/Header';
import Footer from "./Sections/footer/Footer";
import Gradient from "./Sections/Gradient";
import Video from "./Sections/Video/Video.tsx";
import Cookies from "./components/Cookies/Cookies";
// @ts-ignore
import { getSpeakers, getPrograms, getStreams } from './api';
// @ts-ignore
import Speakers, { Speaker } from "./Sections/Speakers/Speakers";
import Programs, { Hall } from "./Sections/Programs/Programs";

// @ts-ignore
import { data } from "./data.ts"

import './styles/fonts.scss'
import './App.scss';

function App() {
    const sheduleRef = useRef(null)
    const [speakers, setSpeakers] = useState<Speaker[]>([])
    const [halls, setHalls] = useState<Hall[]>([])
    const [streams, setStreams] = useState<{title: string, items: any[]}>()

    useEffect(() => {
        if (window.location.hash === '#schedule') {
            sheduleRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }
        getStreams().then( res => {
            const newData = {
                title: "Запись конференции",
                items: res
            }
            setStreams(newData)
        })
        getSpeakers().then(setSpeakers)
        getPrograms().then(setHalls)
    }, [])

    useEffect(() => {
        if (window.location.hash === '#schedule') {
            sheduleRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [halls])

    return (
        <div className="sbergile_talks_2024">
            <div className="sbergile_talks_2024__conteiner">
                <Gradient />
                <Header header={data.header} />
                {/*<Stub />*/}
                {streams && <Video data={streams} />}
                <Speakers speakers={speakers} title="Спикеры" />
                <div ref={sheduleRef}>
                    <Programs halls={halls} title="Программы" />
                </div>
                <Video data={data.howItWas} />
                <Footer footer={data.footer} />
                <Cookies />
            </div>
        </div>
    );
}

export default App;
