import React from 'react';
import { useCookies } from "react-cookie";

import './Cookies.scss'

const Cookies = () => {
    const [cookies, setCookie] = useCookies(['cookies-SbergileTalks2024'])

    if (!!cookies['cookies-SbergileTalks2024']) {
        return null
    }

    return (
        <div className="cookies">
            <div className="cookies__banner">
                <div className="cookies__banner_text">
                    СберБанк обрабатывает cookies с целью персонализации сервисов и чтобы пользоваться веб-сайтом было удобнее. Вы можете запретить обработку сookies в настройках браузера. Пожалуйста, ознакомьтесь <a
                    href="https://www.sberbank.ru/privacy/cookies" target={'_blank'} rel="noreferrer">с политикой использования cookies</a>. Читайте подробнее, <a
                    href="https://www.sberbank.ru/privacy" target={'_blank'} rel="noreferrer">как СберБанк защищает ваши персональные данные</a>.
                </div>
                <div
                    className="cookies__banner_button"
                    onClick={() => setCookie('cookies-SbergileTalks2024', true)}
                >
                    Принять
                </div>
            </div>
        </div>
    );
};

export default Cookies;