import React, { useState } from 'react';

import './Footer.scss';
import { sendToAnalytics } from '../../utils/analytics';

type FooterProps = {
    footer: {
        license: string
        mail: string
        subtitle: string
        textArea: string
    }
}

const Footer = ({ footer }: FooterProps) => {
    const currentYear = new Date().getFullYear();
    const [isToggle, setToggle] = useState<string>('');
    const [isSendFeedback, setSendFeedback] = useState<boolean>(false)
    const [textFeedbak, changeTextFeedback] = useState<string>('')

    const onClickLikes = (grade) => {
        setToggle(grade)
        sendToAnalytics('toggle_grade', grade);
    }

    const onSendFeedback = () => {
        sendToAnalytics('send_feedback', `${isToggle}: ${textFeedbak}`);
        setSendFeedback(true)
    }

    const onChangeText = (text) => {
        if (300 - text.length >= 0) {
            changeTextFeedback(text)
        }
    }

    return (
        <div className="footer">
            <div className="footer__separator" />
            <div className="footer__content">
                <div className="footer__content_license">
                    {footer.license}
                </div>
                <div className='footer__content_contacts'>
                    <div className='footer__content_contacts_item'>
                        <div>{footer.contacts.address.name}</div>
                        <div>{footer.contacts.address.value}</div>
                    </div>
                    <div className='footer__content_contacts_item'>
                        <div>{footer.contacts.mail.name}</div>
                        <a href={`mailto:${footer.contacts.mail.value}`}>{footer.contacts.mail.value}</a>
                    </div>
                </div>
                <div className="footer__content_info">
                    © 1997—{currentYear}
                    <a className="footer__bank" target="_blank" href="https://www.sberbank.ru/ru/"> ПАО Сбербанк</a>
                </div>
                <div className="footer__content_likes">
                    {
                        !isSendFeedback? (
                            <>
                                <div>Страница была вам полезна?</div>
                                <div className="footer__content_likes_wrapper">
                                    <div
                                        className={`footer__content_likes_button${isToggle === 'like' ? '_active' : ''}`}
                                        onClick={() => onClickLikes('like')}
                                    >
                                        <SvgLike />Да
                                    </div>
                                    <div
                                        className={`footer__content_likes_button${isToggle === 'dislike' ? '_active' : ''}`}
                                        onClick={() => onClickLikes('dislike')}
                                    >
                                        <SvgLike dislike />Нет
                                    </div>
                                </div>
                                {
                                    isToggle && (
                                        <div>
                                            <textarea
                                                className="footer__feedback"
                                                placeholder={footer.textArea}
                                                value={textFeedbak}
                                                onChange={ (e) => onChangeText(e.target.value)}
                                            />
                                            <div>
                                                Осталось {300 - textFeedbak.length} символов
                                            </div>
                                            <div className="footer__feedback_button" onClick={() => onSendFeedback()}>
                                                Отправить
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                            <div className="footer__feedback_done">
                                <SvgDone />Спасибо за ваш отзыв!
                            </div>
                        )
                    }
                    
                </div>
            </div>
        </div>
    );
};

export default Footer;

const SvgLike = ({dislike}: {dislike?: boolean}) => {
    return (
        <svg style={dislike ? {transform: 'rotate(180deg)'} : {}} width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <clipPath id="clip388_61">
                    <rect width="16.000000" height="16.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <rect width="16.000000" height="16.000000" fill="#FFFFFF" fill-opacity="0"/>
            <g clipPath="url(#clip388_61)">
                <path d="M4.66 14.66L4.66 7.33M2.66 14.66C1.93 14.66 1.33 14.07 1.33 13.33L1.33 8.66C1.33 7.93 1.93 7.33 2.66 7.33L4.23 7.33C4.49 7.33 4.73 7.17 4.84 6.93L7.18 1.65C7.27 1.46 7.47 1.33 7.68 1.33C8.59 1.33 9.33 2.07 9.33 2.97L9.33 5.33C9.33 5.7 9.63 6 10 6L12.33 6C13.56 6 14.5 7.09 14.31 8.3L13.59 12.97C13.44 13.94 12.6 14.66 11.61 14.66L2.66 14.66Z" stroke="#808790" stroke-opacity="1.000000" stroke-width="1.000000" stroke-linejoin="round" stroke-linecap="round"/>
            </g>
        </svg>
    )
}

const SvgDone = () => {
    return (
        <svg width="44.000000" height="33.663513" viewBox="0 0 44 33.6635" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <filter id="filter_334_2350_dd" x="8.492188" y="7.991882" width="23.515625" height="18.046448" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="0" dy="3"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
                </filter>
            </defs>
            <path id="Vector 721" d="M0 23.19L0 6C0 2.68 2.68 0 6 0L32.83 0C36.14 0 38.83 2.68 38.83 6L38.83 23.88L42.8 31.39C43.22 32.19 42.44 33.1 41.58 32.8L37.71 31.42L31.42 29.19L6 29.19C2.68 29.19 0 26.5 0 23.19Z" fill="#5AB0FF" fill-opacity="1.000000" fill-rule="evenodd"/>
            <g filter="url(#filter_334_2350_dd)">
                <path id="Vector 722" d="M13.5 14L17.69 17.9C17.88 18.08 18.17 18.08 18.36 17.9L27 10" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="4.000000" stroke-linecap="round"/>
            </g>
        </svg>

    )
}