

export const getSpeakers = () => {
    return fetch('/proxy/services/dictionary/shared/api/speakers/period/7')
        .then(res => res.json())
        .then(data => data.map((item) => {
            return {
                id: item.id,
                fullName: item.fullName,
                workPosition: item.workPosition,
                workPlace: item.workPlace,
                imageUrl: item.imageUrl
            }
        }))
        .catch(error => {
            console.warn(error)
            return []
        })
}

export const getPrograms = () => {
    return fetch('/proxy/services/dictionary/shared/api/v2/schedule/period/7')
        .then(res => res.json())
        .then(data => {
            return data[0].halls
        })
        .catch(error => {
            console.warn(error)
            return []
        })
}


const mockStreams = [
    {
      "id": 1,
      "title": "Большой зал",
      "video": "https://front.finevid.link/voteplaer/pl/160"
    },
    {
      "id": 2,
      "title": "Малый зал",
      "video": "https://front.finevid.link/voteplaer/pl/161"
    }
  ]

export const getStreams = async () => {
    try {
        const res = await fetch('/common/assets/sbergiletalks/streams.json')
        return await res.json()
    } catch (error) {
        console.warn(error)
        return mockStreams
    }
}