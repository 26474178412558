import React, { useState, useCallback } from 'react';

import { sendToAnalytics, sendToAnalyticsOnce } from '../../utils/analytics';

import './Video.scss';

type Item = { id: number; title: string; video: string; videoPreview?: string }

type VideoProps = {
    data: {
        title: string;
        items: Item[]
    };
};

const Video = ({ data }: VideoProps) => {
    const [activeItem, setActiveItem] = useState(data.items[0]);
    const [enterVideo, setEnterVideo] = useState<string | undefined>(undefined)

    document.getElementById(activeItem.title)?.addEventListener("mouseenter", (e) => {
        e.stopPropagation()
        e.preventDefault()
        setEnterVideo(e?.target?.id)
    })

    document.getElementById(activeItem.title)?.addEventListener("mouseleave", (e) => {
        e.stopPropagation()
        e.preventDefault()
        setEnterVideo(undefined)
    })

    window.addEventListener("blur", () => {
        setTimeout(() => {
          if (document?.activeElement?.tagName === "IFRAME" && enterVideo === activeItem.title) {
            sendToAnalyticsOnce('play_stream', activeItem.title)
          }
        });
      });

    const handleItemClick = useCallback((e: React.MouseEvent<HTMLButtonElement>, id: number, name: string) => {
        e.stopPropagation()
        e.preventDefault()
        sendToAnalytics('switch_stream', name)
        setActiveItem(data.items.find((item) => item.id === id) || data.items[0])
    }, [data.items])

    return (
        <div className="video">
            <div className="video__title">{data.title}</div>
            <div className="video__items">
                {data.items.map((item) => {
                    const isActive = activeItem.id === item.id

                    return (
                        <button key={item.id} className={`video__items_item ${isActive ? 'video__items_item--active' : ''}`} onClick={(e) => handleItemClick(e, item.id, item.title)}>
                            {item.title}
                        </button>
                    )
                })}
            </div>

            <div
                className="video__frame"
                style={{position: 'relative', paddingBottom: '56.25%', height: '0', width: '100%'}}
            >
            <iframe
                id={activeItem.title}
                src={activeItem.video}
                style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
            />
            </div>
        </div>
    );
}

export default Video;
