import React from 'react';

import './index.scss';

const Gradient = () => {
    return (
        <>
            <div className="gradient gradient__top_left" />
            {/*<div className="gradient gradient__top_right" />*/}
        </>
    );
};

export default Gradient;