const isProd = process.env.NODE_ENV !== 'development'
!isProd && console.log('%cAnalytics dev mode', 'padding: 30px 0; font-weight: bold; font-size: 25px;color: blue; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');

export const sendToAnalytics = (action, label = '') => {
  if (document) {
    const pushObject = {
      event: 'TALKS_PageReview',
      action,
      label,
    };

    if (isProd) {
      // eslint-disable-next-line no-undef
      dataLayer.push(pushObject)
    } else {
      console.log('%c Send analytics (dev mode) ', 'color: white; background-color: rgb(226,91,14);', pushObject)
    }
  }
}


const actions = [];
export const sendToAnalyticsOnce = (action, label = '') => {
  if (!actions.find(el => el === `${action}_${label}`)) {
    actions.push(`${action}_${label}`)
    return sendToAnalytics(action, label)
  }
}