import React, {useCallback, useEffect, useMemo, useState} from 'react';
import IconImage from '../../assets/advertising_16.svg';
import CentralImage from '../../assets/centralImage.webp';
import { useBreakpoint } from "../../utils/useBreakpoint";
import moment from 'moment'

import './Programs.scss';

type Speaker = {
    id: number;
    fullName: string;
    imageUrl: string;
    workPlace?: string;
    workPosition?: string;
    isLead: boolean
};

type Topic = {
    id: number;
    modifiedAt: string
    createdAt: string;
    title: string;

    annotation?: string;
    description?: string;
    endDate?: string;
    isBreak?: boolean;
    leadSpeakers?: Speaker[];
    speakers?: Speaker[];
    startDate?: string;
}

export type Hall = {
    id: number;
    name: string;
    topics: Topic[];
};

export type ProgramsProps = {
    title: string;
    halls: Hall[]
};

const Programs = ({ halls, title }: ProgramsProps) => {
    const useDevise = useBreakpoint();

    const [activeHall, setActiveHall] = useState(halls[0])

    useEffect(() => {
        if (halls.length !== 0) {
            setActiveHall(halls[0])
        }
    }, [halls])

    const handleHallClick = useCallback((id: number) => {
        const newActiveHall = halls.find((h) => h.id == id) || halls[0];
        setActiveHall(newActiveHall)
    }, [halls])

    // const currentTopics = useMemo(() => {
    //     if (activeHall && activeHall.topics) {
    //         return activeHall.topics.filter((t) => t.annotation === activeHall.name)
    //     }
    //
    //     return []
    // }, [activeHall])

    if (!halls) {
        return null
    }

    return (
        <div className="programs">
            <div className="programs__image"><img src={CentralImage} alt="Доп картинка" /></div>
            <div className="programs__container">
                <div className="programs__title">{title}</div>
                <div className="programs__halls">
                    {halls.map((hall) => {
                        const isActive = hall.id === activeHall?.id

                        return (
                            <button
                                key={hall.id}
                                className={`programs__hall ${isActive ? 'programs__hall--active' : ''}`}
                                onClick={() => handleHallClick(hall.id)} >
                                {hall.name}
                            </button>
                        )
                    })}
                </div>
                <div className="programs__topics">
                    {activeHall?.topics?.map((topic) => {
                        const startDate = topic.startDate && new Date(topic.startDate)
                        const endDate = topic.endDate && new Date(topic.endDate)

                        const startTime = startDate && moment(startDate).format('HH:mm')
                        const endTime = endDate && moment(endDate).format('HH:mm')

                        const leadSpeakers = topic.leadSpeakers?.map((s) => ({ ...s, isLead: true })) || []
                        const speakers = (topic.speakers?.filter((sp) => leadSpeakers.findIndex((s) => s.id === sp.id) === -1) || [])
                            .map((s) => ({ ...s, isLead: false }))

                        return (
                            <div key={topic.id} className="programs__topic">
                                {startTime && (
                                    <div className="programs__topicTime">
                                        {startTime}{` - ${endTime}`}
                                    </div>
                                )}
                                <div>
                                    {topic.title && <div className="programs__topicTitle">{topic.title}</div>}
                                    {topic.description && <div className="programs__topicDescription">{topic.description}</div>}
                                    {leadSpeakers.length !== 0 && (
                                        <div className="programs__speakers">
                                            {leadSpeakers.map((speaker) => {
                                                return (
                                                    <div className="programs__speaker">
                                                        <div className="programs__speakerImage">
                                                            <img src={speaker.imageUrl} alt={speaker.fullName} />
                                                        </div>
                                                        <div className="programs__speakerInfo">
                                                            <div className="programs__speakerName">{speaker.fullName}</div>
                                                            <div className="programs__speakerPosition">{speaker.workPosition}</div>
                                                            <div className="programs__speakerPlace">{speaker.workPlace}</div>
                                                            <div className="programs__speakerLead">
                                                                <div className="programs__speakerIcon"><img src={IconImage} alt="иконка" /></div> Модератор
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                    {speakers.length !== 0 && (
                                        <div className="programs__speakers">
                                            {speakers.map((speaker) => {
                                                return (
                                                    <div className="programs__speaker">
                                                        <div className="programs__speakerImage">
                                                            <img src={speaker.imageUrl} alt={speaker.fullName} />
                                                        </div>
                                                        <div className="programs__speakerInfo">
                                                            <div className="programs__speakerName">{speaker.fullName}</div>
                                                            <div className="programs__speakerPosition">{speaker.workPosition}</div>
                                                            <div className="programs__speakerPlace">{speaker.workPlace}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* <a className="programs__btn" target="_blank" href="https://www.sberbank.com/ru/promo/sbergile_talks_2024">Стать участником</a> */}
            </div>
        </div>
    );
}

export default Programs;
